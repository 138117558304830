<script>
import router from "../../router/routes";
import NavBar from "@/components/nav-bar";
import SideBar from "@/components/side-bar";
import Footer from "@/components/footer";
import { api } from "@/state/services";

export default {
  components: { NavBar, SideBar, Footer },
  beforeCreate: function() {
    document.body.className = 'main';
  },
  data() {
    return {
      isMenuCondensed: false,

      plan: {
        modal: false,
        status: null,
        order: {
          id: null,
        },
      },
    };
  },
  created: () => {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
    checkPlan() {
      api.get("plan/check").then((response) => {
        this.plan.status = response.data.status;

        if (response.data.status === "inactive") {
          if (response.data.order.id > 0) {
            this.plan.order.id = response.data.order.id;
          } else {
            this.plan.modal = true;
          }
        }
      });
    },
    choosePlan(id) {
      api
        .post("plan", {
          id: id,
        })
        .then((response) => {
          if (response.data.status === "success") {
            this.plan.modal = false;

            if (this.$route.path == "/") {
              this.$router.push("/store/order/" + response.data.order.id);
            } else {
              this.$router.push("/");
            }
          }
        })
    },
  },
  mounted() {
    this.checkPlan();
  },
};
</script>

<template>
  <div id="layout-wrapper">
    <NavBar :isCondensed="isMenuCondensed" />
    <SideBar :isCondensed="isMenuCondensed" />
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <button v-if="plan && plan.status == 'inactive' && plan.order.id > 0" class="border-0 bg-soft-default rounded p-3 mb-3 text-dark w-100" v-on:click="plan.modal = true">
            Deseja alterar o plano escolhido? Clique aqui!
          </button>
          <slot />
        </div>
      </div>
      <Footer />
    </div>

    <b-modal v-model="plan.modal" title="Escolha seu plano" size="xl" centered no-close-on-esc no-close-on-backdrop hide-header-close hide-header hide-footer>
      <div>
        <div class="mt-2 mb-4 text-center">
          <h4>Escolha seu Plano</h4>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-4">
            <div class="bg-soft-light rounded p-3 mb-3 text-center">
              <h3 class="mt-3">Gratitude Expansão</h3>
              <p>
                Acesso a todos os benefícios por 12 meses<br>
                <span class="d-inline-block bg-soft-info px-1 m-1 rounded">Gratitude&nbsp;Livre</span>
                <span class="d-inline-block bg-soft-info px-1 m-1 rounded">Gratitude&nbsp;Placas</span><br>
                <span class="d-inline-block bg-soft-info px-1 m-1 rounded">Gratitude&nbsp;Club</span>
                <span class="d-inline-block bg-soft-info px-1 m-1 rounded">Gratitude&nbsp;Saúde</span>
              </p>
              <hr>
              <h3><strong>R$ 1.599,00</strong> <sup class="text-uppercase font-size-14">Adesão</sup></h3>
              <h5 class="d-none text-secondary"><strong>R$ 999,00</strong> <sup class="text-uppercase font-size-14">Renovação</sup></h5>
              <button class="btn btn-default btn-lg btn-block mt-4 mb-2" v-on:click="choosePlan(4);">
                Escolher
              </button>
            </div>
          </div>
          <div class="col-md-4">
            <div class="bg-soft-light rounded p-3 mb-3 text-center">
              <h3 class="mt-3">Top Líder</h3>
              <p>
                Acesso anual ao Club<br>
                <span class="d-inline-block bg-soft-info px-1 m-1 rounded">Gratitude&nbsp;Club</span>
              </p>
              <hr>
              <h3><strong>R$ 149,90</strong> <sup class="text-uppercase font-size-14">Adesão</sup></h3>
              <h5 class="d-none text-secondary"><strong>R$ 149,90</strong> <sup class="text-uppercase font-size-14">Renovação</sup></h5>
              <button class="btn btn-default btn-lg btn-block mt-4 mb-2" v-on:click="choosePlan(3);">
                Escolher
              </button>
            </div>
          </div>
          <div class="col-md-4">
            <div class="bg-soft-light rounded p-3 mb-3 text-center">
              <h3 class="mt-3">Gratitude Club</h3>
              <p>
                Acesso mensal ao Club<br>
                <span class="d-inline-block bg-soft-info px-1 m-1 rounded">Gratitude&nbsp;Club</span>
                <span class="d-inline-block bg-soft-info px-1 m-1 rounded">Gratitude&nbsp;Saúde</span>
              </p>
              <hr>
              <h3><strong>R$ 49,90</strong> <sup class="text-uppercase font-size-14">Adesão</sup></h3>
              <h5 class="d-none text-secondary"><strong>R$ 39,90</strong> <sup class="text-uppercase font-size-14">Mensalidade</sup></h5>
              <button class="btn btn-default btn-lg btn-block mt-4 mb-2" v-on:click="choosePlan(2);">
                Escolher
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
